import Vue from 'vue'
import Router from 'vue-router'
import { authenticationRequired } from '@/auth'
import rutas_cliente from './router.cliente'
import rutas_admin from './router.admin'
import rutas_proveedor from './router.proveedor'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/Home.vue'),
    },
    {
      path: '/clientes',
      component: () => import('@/views/dashboard/pages/Login'),
    },
    {
      path: '/admin',
      component: () => import('@/views/dashboard/pages/admin/Login.vue'),
    },
    {
      path: '/proveedor',
      component: () => import('@/views/dashboard/pages/proveedor/Login.vue'),
  },
    ...rutas_cliente.map(route => {
      return route
    }),
    ...rutas_admin.map(route => {
      return route
    }),
    ...rutas_proveedor.map(route => {
      return route
    }),
  ],
})
