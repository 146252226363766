import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    drawer: null,
    token: null,
    expireDate: null,
    user: null,
    tipoUser: null,
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    addToken: (state, payload)=>{
      state.token = payload
    },
    addexpireDate: (state, payload)=>{
      state.expireDate = payload
    },
    addUser: (state, payload)=>{
      state.user = null
      state.user = payload
    },
    logout (state) {
      state.user = null
      state.token = null
      state.expireDate = null
      state.drawer = null
      // state.business = null
      // state.lastSimulation = null
    },
    setUser (state, payload) {
      state.tipoUser = payload
    },
  },
  actions: {
    RegisterToken:(store,payload)=>{
      store.commit('addToken',payload)
    },
    RegisterexpireDate:(store,payload)=>{
      store.commit('addexpireDate',payload)
    },
    RegisterUser:(store,payload)=>{
      store.commit('addUser',payload)
    },
    RegisterLogout:(store)=>{
      store.commit('logout',store)
    },
    RegisterTipeUser:(store,payload)=>{
      store.commit('setUser',payload)
    },
  },
})
