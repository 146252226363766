<template>
  <div>
    <v-snackbar
      v-model="snackbar"
      bottom
      :color="color"
      timeout=3000
      multi-line
    >
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog content-class="elevation-0" v-model="dialog" persistent>

     <div class="text-center" style="overflow-y:hidden !important;">
      <v-progress-circular
      :size="70"
      :width="7"
      indeterminate
      color="primary"
      >
      </v-progress-circular>
    </div>
    </v-dialog>
  </div>
</template>

<script>
import { EventBus } from "@/main.js";

export default {
    name: 'Succes',
    data:()=>({ 
        snackbar: false,
        color: null,
        text: null,
        dialog:false
    }),
    beforeMount() {
        EventBus.$on("login", (text) => {
            if(text != ''){
                this.$data.snackbar = false;
                this.$data.snackbar = true;
                this.$data.color = "primary";
                this.$data.text = text;
                }
        });
        EventBus.$on("logout", (text) => {
            if(text != ''){
                this.$data.snackbar = false;
                this.$data.snackbar = true;
                this.$data.color = "red";
                this.$data.text = text;
                }
        });
        EventBus.$on("cargando", (val) => {
                this.$data.dialog = val;
                
        });
    }
}
</script>

<style scoped>

</style>

      