import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/i18n'
import 'vuetify/dist/vuetify.min.css';
import '@/sass/overrides.sass'

Vue.use(Vuetify)

const theme = {
  primary: '9F60B6',
  secondary: '#6E85B7',
  accent: '#FF7878',
  info: '#54BAB9',
  black:'#363636',
  grey: '#9B9797',
  greyDark:'#525050',
  greyLight:'#F1F0F0',
  red:'#FF7878',
  redDark:'#CB5757',
  primaryDark:'#7E5585',
  secondaryDark:'#546995',
  infoDark:'#3C8988'
  
}

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    options: {
      customProperties:true
    },
    themes: {
      dark: theme,
      light: theme,
    },
  },
})
