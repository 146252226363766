
import { authenticationRequired } from "@/auth";
import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/views/dashboard/layout/index.vue'
export default [{
    path: '/clientes/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/Dashboard.vue'),
    beforeEnter: authenticationRequired,
  },
  {
    path: '/clientes/perfil',
    name: 'perfil',
    component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/Mi-perfil.vue'),
    beforeEnter: authenticationRequired,
  },
  {
    name: 'cobros',
    path: '/clientes/cobros',
    component: Layout,
    beforeEnter: authenticationRequired,
    children: [
      {
        name: '',
        path: '',
        component: () => import('@/views/dashboard/pages/MisCobros'),
      },
      {
        path: '/clientes/cobros/ver-cobros/:id',
        name: 'ver-cobros',
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/Ver-cobros.vue'),
        beforeEnter: authenticationRequired,
        props: true,
      },
    ],
  },
  {
    path: '/clientes/productos',
    name: 'productos',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Layout,
    beforeEnter: authenticationRequired,
    children: [
      {
        path: '',
        name: '',
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/Productos.vue'),
      },
      {
      path: '/clientes/productos/ver-producto/:id',
      name: 'ver-productos', 
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/Ver-productos.vue'),
      beforeEnter: authenticationRequired,
      props: true,
      },
      {
      path: '/clientes/relacionar',
      name: 'relacionar-masivamente',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/Relacionar-productos.vue'),
      beforeEnter: authenticationRequired,
      }],
  },
  {
    path: '/clientes/proveedores',
    name: 'proveedores',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/Proveedores.vue'),
    beforeEnter: authenticationRequired,
  },
  {
    path: '/clientes/sa/:id',
    name: 'sa',
    component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/admin/subUser/Proveedores.vue'),
  },
]
