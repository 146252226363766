
import { authenticationRequired } from "@/auth";
import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/views/dashboard/layout/index.vue'
export default [
  {
    name: 'producto',
    path: '/admin/productos',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Layout,
    beforeEnter: authenticationRequired,
    children:[
      {
        name: '',
        path: '',
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/admin/Productos.vue'),
      },
      {
        path: '/admin/ver-producto/:id',
        name: 'ver-productos',
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/admin/Ver-productos.vue'),
        beforeEnter: authenticationRequired,
        props:true
      },
      {
        path: '/admin/editar-producto/:id',
        name: 'editar-productos',
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/admin/Editar-productos.vue'),
        beforeEnter: authenticationRequired,
        props:true
      },
      {
        path: '/admin/crear-producto',
        name: 'crear-productos',
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/admin/Crear-producto.vue'),
        beforeEnter: authenticationRequired,
        props:true
      },
      {
        path: '/admin/edicion-masiva-producto',
        name: 'edicion-masiva-productos',
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/admin/Edicion-masiva-productos.vue'),
        beforeEnter: authenticationRequired,
        props:true
      },
      {
        path: '/admin/creacion-masiva-producto',
        name: 'creacion-masiva-productos',
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/admin/Creacion-masiva-productos.vue'),
        beforeEnter: authenticationRequired,
        props:true
      },
    ],
  },
  {
    path: '/admin/marcas',
    name: 'admin-marcas',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
    component: Layout,
    beforeEnter: authenticationRequired,
    children:[
      {
        name: '',
        path: '',
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/admin/Marca.vue'),
      },{
        path: '/admin/crear-marca',
        name: 'crear-marca',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/admin/Crear-marca.vue'),
        beforeEnter: authenticationRequired,
        props:true
      },
      {
        path: '/admin/editar-marca/:id',
        name: 'editar-marca',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/admin/Editar-marca.vue'),
        beforeEnter: authenticationRequired,
        props:true
      },
      {
        path: '/admin/ver-marca/:id',
        name: 'ver-marca',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/admin/Ver-marca.vue'),
        beforeEnter: authenticationRequired,
        props:true
      },
      {
        path: '/admin/editar-masiva-marca',
        name: 'editar-masiva-marca',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/admin/Edicion-masiva-marcas.vue'),
        beforeEnter: authenticationRequired,
        props:true
      },
      {
        path: '/admin/creacion-masiva-marca',
        name: 'crear-masiva-marca',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/admin/Creacion-masiva-marcas.vue'),
        beforeEnter: authenticationRequired,
        props:true
      }]
  },
  {
    path: '/admin/clientes',
    name: 'clientes',
    component: Layout,
    beforeEnter: authenticationRequired,
    children:[
      {
        name: '',
        path: '',
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/admin/Clientes.vue'),
      },{
        path: '/admin/crear-cliente',
        name: 'crear-clientes',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/admin/Crear-cliente.vue'),
        beforeEnter: authenticationRequired,
        props:true
      },{
        path: '/admin/editar-cliente/:id',
        name: 'editar-cliente',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/admin/Editar-cliente.vue'),
        beforeEnter: authenticationRequired,
        props:true
      },
      {
        path: '/admin/ver-cliente/:id',
        name: 'ver-cliente',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/admin/Ver-cliente.vue'),
        beforeEnter: authenticationRequired,
        props:true
      },
      {
        path: '/admin/editar-cliente/:id/relacionar-producto',
        name: 'relacionar-masivamente-producto',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/admin/Relacionar-productos.vue'),
        beforeEnter: authenticationRequired,
        props:true
        }
    ]
  },
  {
    path: '/admin/proveedores',
    name: 'proveedores',
    component: Layout,
    beforeEnter: authenticationRequired,
    children:[
      {
        path: 'actualizar-stock-masivo',
        name: 'ProveedorActualizarStockMasivo',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/admin/ProveedoresActualizarStockMasivo.vue'),
        beforeEnter: authenticationRequired,
        props:true
      },

      {
        name: '',
        path: '',
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/admin/Proveedores.vue'),
      },
      {
        path: '/admin/ver-proveedor/:id',
        name: 'ver-proveedor',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/admin/Ver-proveedor.vue'),
        beforeEnter: authenticationRequired,
        props:true
      },
      {
        path: '/admin/crear-proveedor',
        name: 'crear-proveedor',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/admin/Crear-proveedor.vue'),
        beforeEnter: authenticationRequired,
        props:true
      },
      {
        path: '/admin/editar-proveedor/:id',
        name: 'editar-proveedor',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/admin/Editar-proveedor.vue'),
        beforeEnter: authenticationRequired,
        props:true
      },
      {
        path: '/admin/creacion-masiva-proveedor',
        name: 'crear-masiva-proveedor',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/admin/Creacion-masiva-proveedores.vue'),
        beforeEnter: authenticationRequired,
        props:true
      },
      {
        path: '/admin/editar-masiva-proveedor',
        name: 'edicion-masiva-proveedor',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/admin/Edicion-masiva-proveedores.vue'),
        beforeEnter: authenticationRequired,
        props:true
      },
      {
        path: '/admin/relacionar-masiva-producto/:id',
        name: 'relacion-masiva-producto',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/admin/ProveedoresProductosRelacionMasiva.vue'),
        beforeEnter: authenticationRequired,
        props:true
      },
      {
      path: '/admin/quitar-relacionar-masiva-producto/:id',
      name: 'quitar-relacion-masiva-producto',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/admin/Quitar-relacion-productos.vue'),
      beforeEnter: authenticationRequired,
      props:true
    },
  ],
  },
  {
    path: '/admin/cobros',
    name: 'cobros',
    component: Layout,
    beforeEnter: authenticationRequired,
    children:[
      {
        name: '',
        path: '',
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/admin/Cobros.vue'),
      },{
        path: '/admin/ver-cobros/:id',
        name: 'ver-cobros',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/admin/Ver-cobros.vue'),
        beforeEnter: authenticationRequired,
        props:true
      }
    ]
  },
  {
    path: '/admin/planes',
    name: 'planes',
    component: Layout,
    beforeEnter: authenticationRequired,
    children:[
      {
        name: '',
        path: '',
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/admin/Planes.vue'),
      },{
        path: '/admin/ver-plan/:id',
        name: 'ver-plan',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/admin/Ver-Plan.vue'),
        beforeEnter: authenticationRequired,
        props:true
      },
      {
        path: '/admin/editar-plan/:id',
        name: 'editar-plan',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/admin/Editar-plan.vue'),
        beforeEnter: authenticationRequired,
        props:true
      },
      {
        path: '/admin/crear-plan',
        name: 'crear-plan',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/admin/Crear-plan.vue'),
        beforeEnter: authenticationRequired,
        props:true
      }
    ]
  },

  {
    path: '/admin/opciones',
    name: 'opciones',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/admin/Configuracion.vue'),
    beforeEnter: authenticationRequired,
    props:true
  },
]
