import Axios from "axios";
import { Configuration } from "@/configuration";

Axios.defaults.headers["Content-Type"] = "application/json";

export default {
  login(email, password) {
    let formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    return new Promise((resolve, reject) => {
      Axios.post(`${Configuration.api.base}/clientes/auth`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          console.log(err);
        });
    });
  },
  loginAdmin(email, password) {
    let formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    return new Promise((resolve, reject) => {
      Axios.post(`${Configuration.api.base}/admin/auth`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          console.log(err);
        });
    });
  },
  loginProveedor(email, password) {
    let formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    return new Promise((resolve, reject) => {
      Axios.post(`${Configuration.api.base}/proveedores/auth`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          console.log(err);
        });
    });
  },
  loginProveedor(email, password) {
    let formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    return new Promise((resolve, reject) => {
      Axios.post(`${Configuration.api.base}/proveedores/auth`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          resolve(res);
          console.log(res);
        })
        .catch((err) => {
          reject(err);
          console.log(err);
        });
    });
  },
  verCliente(token, user_id) {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/clientes/${user_id}?token=${token}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  verAdmin(token) {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/admin/1?token=${token}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  verProveedor(token, user_id) {
    return new Promise((resolve, reject) => {
      Axios.get(
        `${Configuration.api.base}/proveedores/${user_id}?token=${token}`
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  editarCliente(user, token) {
    let formData = new FormData();
    formData.append("nombre", user.nombre);
    if (user.password) {
      formData.append("password", user.password);
    }
    if (user.avatar) {
      formData.append("avatar", user.avatar);
    }
    formData.append("rut", user.rut);
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/clientes/editar/${user.id}?token=${token}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  cobrosCliente(
    token,
    id_cliente,
    direction,
    order,
    desde,
    hasta,
    estado,
    page,
    limit
  ) {
    return new Promise((resolve, reject) => {
      Axios.get(
        `${Configuration.api.base}/clientes/${id_cliente}/cobros/list?token=${token}&desde=${desde}&hasta=${hasta}&pagado=${estado}&order_by=${order}&order_direction=${direction}&limit=${limit}&page=${page}`
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  verCobroproducto(idcobro, token) {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/cobros/${idcobro}?token=${token}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  productosClientes(
    id,
    token,
    direction,
    order,
    id_producto,
    nombre,
    marca_id,
    ean,
    upc,
    page,
    limit
  ) {
    return new Promise((resolve, reject) => {
      Axios.get(
        `${Configuration.api.base}/clientes/${id}/productos/list?token=${token}&id_interno=${id_producto}&id=&nombre=${nombre}&upc=${upc}&ean=${ean}&marca_id=${marca_id}&activo=1&order_by=${order}&order_direction=${direction}&limit=${limit}&page=${page}`
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  productosAdmin(
    token,
    id,
    direction,
    order,
    nombre,
    ac,
    marca_id,
    ean,
    upc,
    page,
    limit
  ) {
    return new Promise((resolve, reject) => {
      Axios.get(
        `${Configuration.api.base}/productos/list?token=${token}&nombre=${nombre}&marca_id=${marca_id}&id=${id}&upc=${upc}&ean=${ean}&activo=${ac}&order_direction=${direction}&limit=${limit}&order_by=${order}&page=${page}`
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  verProducto(id, mi_id, token) {
    return new Promise((resolve, reject) => {
      Axios.get(
        `${Configuration.api.base}/clientes/${id}/productos/${mi_id}?token=${token}`
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  verProductoAdmin(id, token) {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/productos/${id}?token=${token}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  verProductoProveedor(id, token, id_proveedor) {
    return new Promise((resolve, reject) => {
      Axios.get(
        `${Configuration.api.base}/productos/${id_proveedor}/${id}?token=${token}`
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  relacionarProducto(id_prod, mi_id, token, idclient) {
    let formData = new FormData();
    formData.append("productos[0][id]", id_prod);
    formData.append("productos[0][id_interno]", mi_id);
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/clientes/${idclient}/productos/agregar?token=${token}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  quitarRelacionProducto(id_prod, id, token) {
    let formData = new FormData();
    formData.append("productos[1][id]", id_prod);
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/clientes/${id}/productos/quitar?token=${token}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  obtenerArchivoExcel(id, token) {
    return new Promise((resolve, reject) => {
      Axios.get(
        `${Configuration.api.base}/clientes/${id}/productos/exportar?token=${token}`
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  relacionmasivaProductos(formData, id, token) {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/clientes/${id}/productos/mass?token=${token}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  relacionProveedoresMasivaProductos(formData, token, id_proveedor) {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/proveedores/sync/productos/mass/${id_proveedor}?token=${token}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          // info=err.response.data
        });
    });
    // return relacionProveedoresMasivaProductos.data;
  },
  quitarRelacionProveedorMasivo(formData, token, id_proveedor) {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/proveedores/productos/quitar/masivamente/${id_proveedor}?token=${token}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  obtenerArchivoFormatoProveedorProductoExcel(token) {
    return new Promise((resolve, reject) => {
      Axios.get(
        `${Configuration.api.base}/proveedores/exportar-formato-masivo-productos?token=${token}`
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  obtenerArchivoFormatoProveedorDeleteProductoExcel(token, id_proveedor) {
    return new Promise((resolve, reject) => {
      Axios.get(
        `${Configuration.api.base}/proveedores/exportar-formato-masivo-delete-productos/${id_proveedor}?token=${token}`
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarProveedores(id, token, direction, order, id_prod, nombre, page, limit) {
    return new Promise((resolve, reject) => {
      Axios.get(
        `${Configuration.api.base}/clientes/${id}/proveedores/list?token=${token}&id=${id_prod}&nombre=${nombre}&producto_id=&producto_nombre&limit=${limit}&order_by=${order}&order_direction=${direction}&disponibilidad=&page=${page}`
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  relacionarProveedor(id_proveedor, token, id_cliente) {
    let formData = new FormData();
    formData.append("proveedores[0][id]", id_proveedor);
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/clientes/${id_cliente}/proveedores/agregar?token=${token}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  quitarRelacionClienteProveedores(id_cliente, token, id_proveedor) {
    let formData = new FormData();
    formData.append("proveedores[0][id]", id_proveedor);
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/clientes/${id_cliente}/proveedores/quitar?token=${token}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  quitarRelacionProveedores(id_producto, token, id_proveedor) {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/productos/${id_producto}/proveedores/quitar/${id_proveedor}?token=${token}`
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarMarcas(token) {
    return new Promise((resolve, reject) => {
      Axios.get(
        `${Configuration.api.base}/marcas/list?token=${token}&nombre=&order_direction=desc&limit=1000`
      )
        .then((res) => {
          resolve(res);
          //console.log(res)
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  ///////// apis admin //////////
  exportarProductoAdmin(token) {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/productos/exportar?token=${token}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  registrarProducto(token, producto) {
    let formData = new FormData();
    formData.append("nombre", producto.nombre);
    formData.append("marca_id", producto.marca);
    formData.append("codigo_interno", producto.cod_interno);
    formData.append("upc", producto.upc);
    formData.append("ean", producto.ean);
    formData.append("imagen", producto.logo);
    formData.append("activo", producto.activo);

    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/productos/crear?token=${token}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async eliminarProducto({ productos, token }) {
    const body = JSON.stringify({ productos: productos.map((id) => ({ id })) });
    let info= {};
    try{
      const resp = await Axios.post(
        `${Configuration.api.base}/productos/destroy?token=${token}`,
        body,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      info = resp.data
    }
    catch(error){
      info = error.response.data
    }
    finally{return info}
  },
  editarProducto(token, producto, id_producto) {
    let formData = new FormData();
    formData.append("nombre", producto.nombre);
    formData.append("marca_id", producto.marca_id);
    formData.append("codigo_interno", producto.codigo_interno);
    formData.append("upc", producto.upc);
    formData.append("ean", producto.ean);
    formData.append("imagen", producto.imagen);
    formData.append("activo", producto.activo);
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/productos/editar/${id_producto}?token=${token}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async editarMasivaProduct(formData, token) {
    const edicionMasivaProducto = await Axios.post(
      `${Configuration.api.base}/productos/editar/masivo?token=${token}`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return edicionMasivaProducto.data;
  },
  async editarMasivaProductStock(formData, token, id_proveedor) {
    const edicionMasivaProducto = await Axios.post(
      `${Configuration.api.base}/productos/editarStock/masivo/${id_proveedor}?token=${token}`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return edicionMasivaProducto.data;
  },
  async crearMasivaProduct(formData, token) {
    const crearMasivaProducto = await Axios.post(
      `${Configuration.api.base}/productos/crear/masivo?token=${token}`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return crearMasivaProducto.data;
  },
  async exportMasivaProduct(token) {
    const exportMasivaProduct = await Axios.get(
      `${Configuration.api.base}/productos/exportar-formato-masivo?token=${token}`,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return exportMasivaProduct.data;
  },
  relacionarNuevoProveedor(token, id_producto, producto) {
    //duda con los datos
    let formData = new FormData();
    formData.append("sku", producto.sku);
    formData.append("stock", producto.stock);

    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/productos/${id_producto}/proveedores/agregar/${producto.id_proveedor}?token=${token}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  quitarRelacionProveedor(token, id, id_proveedor) {
    //duda con los datos
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/productos/${id}/proveedores/quitar/${id_proveedor}?token=${token}`,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  registrarNuevoCliente(token, id_cliente) {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/clientes/9/productos/agregar?token=${token}`,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarMarcasAdmin(
    token,
    id,
    name,
    activo,
    orderby,
    order,
    page,
    limit,
    proveedor
  ) {
    return new Promise((resolve, reject) => {
      Axios.get(
        `${Configuration.api.base}/marcas/list?token=${token}&id=${id}&nombre=${name}&activo=${activo}&order_by=${orderby}&order_direction=${order}&limit=${limit}&page=${page}&proveedor=${proveedor}`
      )
        .then((res) => {
          resolve(res);
          //console.log(res)
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  verMarcasAdmin(token, id) {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/marcas/${id}?token=${token}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  crearMarcasAdmin(token, marca) {
    let formData = new FormData();
    formData.append("nombre", marca.nombre);
    formData.append("logo", marca.logo);
    formData.append("activo", marca.activo);
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/marcas/crear?token=${token}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async editarMasivaMarcas(formData, token) {
    const editarMasivaMarcas = await Axios.post(
      `${Configuration.api.base}/marcas/editar/mass?token=${token}`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return editarMasivaMarcas.data;
  },
  async crearMasivaMarcas(formData, token) {
    const crearMasivaProducto = await Axios.post(
      `${Configuration.api.base}/marcas/crear/mass?token=${token}`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return crearMasivaProducto.data;
  },
  editarMarcaAdmin(token, id, marca) {
    let formData = new FormData();
    formData.append("nombre", marca.nombre);
    // formData.append('logo',marca.logo2)
    formData.append("activo", marca.activo);
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/marcas/editar/${id}?token=${token}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  verMarcaAdmin(token, id_marca) {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/marcas/${id_marca}?token==${token}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  exportarMarcasAdmin(token) {
    return new Promise((resolve, reject) => {
      Axios.get(
        `${Configuration.api.base}/marcas/exportar?token=${token}&id&nombre&activo&order_by&order_direction&proveedor`
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  exportarFormatoMarcasAdmin(token) {
    return new Promise((resolve, reject) => {
      Axios.get(
        `${Configuration.api.base}/marcas/exportar-formato-masivo?token=${token}&id&nombre&activo&order_by&order_direction&proveedor`
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarProveedoresAdmin(token) {
    return new Promise((resolve, reject) => {
      Axios.get(
        `${Configuration.api.base}/proveedores/list?token=${token}&email=&nombre=&activo=1&order_by=id&order_direction=asc`
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarClientes(token) {
    return new Promise((resolve, reject) => {
      Axios.get(
        `${Configuration.api.base}/clientes/list?token=${token}&plan_id=&email=&rut=&nombre=&activo=1&limit=100&order_by=id&order_direction=asc`
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarClientesAdmin(
    token,
    id,
    plan_id,
    email,
    rut,
    nombre,
    activo,
    order,
    direction,
    limit,
    page
  ) {
    return new Promise((resolve, reject) => {
      Axios.get(
        `${Configuration.api.base}/clientes/list?token=${token}&id=${id}&plan_id=${plan_id}&email=${email}&rut=${rut}&nombre=${nombre}&activo=${activo}&limit=${limit}&order_by=${order}&order_direction=${direction}&limit=${limit}&page=${page}`
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarPlanesAdmin2(token) {
    return new Promise((resolve, reject) => {
      Axios.get(
        `${Configuration.api.base}/planes/list?token=${token}&activo=1&order_by=id&order_direction=asc&limit=10`
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async exportarClientes(token) {
    const exportarClientes = await Axios.get(
      `${Configuration.api.base}/clientes/exportar?token=${token}`
    );
    return exportarClientes;
  },
  crearClienteAdmin(token, cliente) {
    let formData = new FormData();
    formData.append("plan_id", cliente.plan_id);
    formData.append("email", cliente.email);
    formData.append("password", cliente.password);
    formData.append("nombre", cliente.nombre);
    formData.append("rut", cliente.rut);
    formData.append("avatar", cliente.avatar);
    formData.append("activo", cliente.activo);
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/clientes/crear?token=${token}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  verClienteAdmin(token, id) {
    return new Promise((resolve, reject) => {
      Axios.get(`${Configuration.api.base}/clientes/${id}?token=${token}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  editarClienteAdmin(user, token, id) {
    let formData = new FormData();
    formData.append("nombre", user.nombre);
    if (user.password) {
      formData.append("password", user.password);
    }
    if (user.email) {
      formData.append("email", user.email);
    }
    if (user.avatar) {
      formData.append("avatar", user.avatar);
    }
    if (user.plan_id) {
      formData.append("plan_id", user.plan_id);
    }
    if (user.activo) {
      formData.append("activo", user.activo);
    }
    formData.append("rut", user.rut);
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/clientes/editar/${id}?token=${token}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listarProveedoresAdmin2(
    token,
    direction,
    order,
    id,
    nombre,
    email,
    estado,
    page,
    itemsPerPage
  ) {
    return new Promise((resolve, reject) => {
      Axios.get(
        `${Configuration.api.base}/proveedores/list?token=${token}&email=${email}&id=${id}&nombre=${nombre}&activo=${estado}&limit=${itemsPerPage}&order_by=${order}&order_direction=${direction}&page=${page}`
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  verProveedorAdmin(token, id_proveedor) {
    return new Promise((resolve, reject) => {
      Axios.get(
        `${Configuration.api.base}/proveedores/${id_proveedor}?token=${token}`
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  editarProveedorAdmin(token, id_proveedor, proveedor) {
    let formData = new FormData();
    const keys = [
      "email",
      "nombre",
      "password",
      "logo",
      "activo",
      "direccion_comercial",
      "disponibilidad",
      "ftp_host",
      "ftp_usuario",
      "ftp_password",
      "ftp_puerto",
      "frecuencia_ftp",
      "permitir_actualizar_stock",
    ];
    keys.forEach((key) => {
      if (proveedor.hasOwnProperty(key)) {
        formData.append(key, proveedor[key] ?? "");
      }
    });

    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/proveedores/editar/${id_proveedor}?token=${token}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err.response);
          reject(err);
        });
    });
  },
  async crearNuevoProveedor(token, proveedor) {
    let formData = new FormData();
    formData.append("email", proveedor.email);
    formData.append("nombre", proveedor.nombre);
    formData.append("password", proveedor.password);
    formData.append("logo", proveedor.logo);
    formData.append("activo", proveedor.activo);
    formData.append("direccion_comercial", proveedor.direccion_comercial);
    formData.append("disponibilidad", proveedor.disponibilidad);
    formData.append("ftp_host", proveedor.ftp_host);
    formData.append("ftp_usuario", proveedor.ftp_usuario);
    formData.append("ftp_password", proveedor.ftp_password);
    formData.append("ftp_puerto", proveedor.ftp_puerto);
    const crearNuevoProveedor = await Axios.post(
      `${Configuration.api.base}/proveedores/crear?token=${token}`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return crearNuevoProveedor;
  },
  async exportarformatoProveedores(token) {
    const exportarProveedores = await Axios.get(
      `${Configuration.api.base}/proveedores/exportar?token=${token}`
    );
    return exportarProveedores;
  },
  async exportarformatoMasivoProveedores(token) {
    const exportarformatoProveedores = await Axios.get(
      `${Configuration.api.base}/proveedores/exportar-formato-masivo?token=${token}`
    );
    return exportarformatoProveedores;
  },
  async editarMasivaProveedores(formData, token) {
    const editarMasivaProveedores = await Axios.post(
      `${Configuration.api.base}/proveedores/editar/masivo?token=${token}`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return editarMasivaProveedores;
  },

  async crearMasivaProveedores(formData, token) {
    const crearMasivaProveedores = await Axios.post(
      `${Configuration.api.base}/proveedores/agregar/masivo?token=${token}`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return crearMasivaProveedores;
  },
  async listarCobrosAdmin(
    token,
    id_pago,
    cliente_id,
    desde,
    hasta,
    pagado,
    id,
    order,
    page,
    limit
  ) {
    const listaCobros = await Axios.get(
      `${Configuration.api.base}/cobros/list?token=${token}&id=${id_pago}&cliente_id=${cliente_id}&desde=${desde}&hasta=${hasta}&pagado=${pagado}&order_by=${id}&order_direction=${order}&limit=${limit}&page=${page}`
    );
    return listaCobros.data;
  },
  async listarCobrosAdmin2(
    token,
    desde,
    hasta,
    pagado,
    id,
    order,
    page,
    limit
  ) {
    const listaCobros = await Axios.get(
      `${Configuration.api.base}/cobros/list?token=${token}&id=&cliente_id=&desde=${desde}&hasta=${hasta}&pagado=${pagado}&order_by=${id}&order_direction=${order}&limit=${limit}&page=${page}`
    );
    return listaCobros.data;
  },
  async listarCobrosAdminEditar(
    token,
    id_cliente,
    desde,
    hasta,
    pagado,
    id,
    order,
    page,
    limit
  ) {
    const listaCobros = await Axios.get(
      `${Configuration.api.base}/cobros/list?token=${token}&cliente_id=${id_cliente}&desde=${desde}&hasta=${hasta}&pagado=${pagado}&order_by=${id}&order_direction=${order}&limit=${limit}&page=${page}`
    );
    return listaCobros.data;
  },
  async verCobrosAdmin(token, id) {
    const verCobros = await Axios.get(
      `${Configuration.api.base}/cobros/${id}?token=${token}`
    );
    return verCobros.data;
  },
  async finalizarCobrosAdmin(token, id, observacion) {
    let formData = new FormData();
    formData.append("observaciones", observacion);
    const finalizarCobros = await Axios.post(
      `${Configuration.api.base}/cobros/${id}/finalizar?token=${token}`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return finalizarCobros.data;
  },
  async reversarCobrosAdmin(token, id, motivo) {
    let formData = new FormData();
    formData.append("razon_reversa", motivo);
    const reversarCobros = await Axios.post(
      `${Configuration.api.base}/cobros/${id}/reversar?token=${token}`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return reversarCobros.data;
  },
  async listarPlanesAdmin(
    token,
    id,
    nombre,
    ilimitado,
    activo,
    order,
    direction,
    page,
    limit
  ) {
    const listarPlanesAdmin = await Axios.get(
      `${Configuration.api.base}/planes/list?token=${token}&id=${id}&nombre=${nombre}&ilimitado=${ilimitado}&activo=${activo}&order_by=${order}&order_direction=${direction}&limit=${limit}&page=${page}`
    );
    return listarPlanesAdmin.data;
  },
  async verPlanAdmin(token, id) {
    const verPlanAdmin = await Axios.get(
      `${Configuration.api.base}/planes/${id}?token=${token}`
    );
    return verPlanAdmin.data;
  },
  async editarPlanAdmin(token, id, plan) {
    let formData = new FormData();
    formData.append("nombre", plan.nombre);
    formData.append("activo", plan.activo);
    formData.append("descripcion", plan.descripcion);
    formData.append("precio_peticion", plan.precio_peticion);
    formData.append("limite_diario", plan.limite_diario);
    formData.append("ilimitado", plan.ilimitado);
    formData.append("precio_sobre_consumo", plan.precio_sobre_consumo);
    const editarPlanAdmin = await Axios.post(
      `${Configuration.api.base}/planes/editar/${id}?token=${token}`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return editarPlanAdmin;
  },
  async crearPlanAdmin(token, plan) {
    let formData = new FormData();
    formData.append("nombre", plan.nombre);
    formData.append("activo", plan.activo);
    formData.append("descripcion", plan.descripcion);
    formData.append("precio_peticion", plan.precio_peticion);
    formData.append("limite_diario", plan.limite_diario);
    formData.append("ilimitado", plan.ilimitado);
    formData.append("precio_sobre_consumo", plan.precio_sobre_consumo);
    const editarPlanAdmin = await Axios.post(
      `${Configuration.api.base}/planes/crear?token=${token}`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return editarPlanAdmin;
  },
  async listarProductosUser(
    token,
    id_user,
    nombre,
    id_interno,
    marca_id,
    order,
    direction,
    limit
  ) {
    const listarProductosUser = await Axios.get(
      `${Configuration.api.base}/clientes/${id_user}/productos/list?token=${token}&id_interno=${id_interno}&nombre=${nombre}&marca_id=${marca_id}&activo=1&order_by=${order}&order_direction=${direction}&limit=${limit}`
    );
    return listarProductosUser.data;
  },
  async listarProductosProveedores(
    token,
    id_proveedor,
    direction,
    order,
    nombre,
    sku,
    marca_id,
    ean,
    upc,
    page,
    limit
  ) {
    const listarProductos = await Axios.get(
      `${Configuration.api.base}/proveedores/${id_proveedor}/productos?token=${token}&marca_id=${marca_id}&nombre=${nombre}&codigo_interno=&sku=${sku}&upc=${upc}&ean=${ean}&activo=&limit=10&order_by=${order}&order_direction=${direction}&page=${page}`
    );
    return listarProductos;
  },
  async listarClientesProveedores(
    token,
    id_cliente,
    id,
    email,
    rut,
    nombre,
    page
  ) {
    const listarClientes = await Axios.get(
      `${Configuration.api.base}/proveedores/${id_cliente}/clientes/list?token=${token}&id=${id}&email=${email}&rut=${rut}&nombre=${nombre}&limit=10&activo=&order_by=id&order_direction=asc&page=${page}`
    );
    return listarClientes;
  },
  async obtenerConfiguracion(token, conf) {
    const configuracion = await Axios.get(
      `${Configuration.api.base}/configuraciones/obtener?token=${token}&configuracion=${conf}`
    );
    return configuracion;
  },
  async actualizarConfiguracionCobro(token, cobro) {
    let formData = new FormData();
    formData.append("nuevo_valor", cobro);
    const actconfiguracion = await Axios.post(
      `${Configuration.api.base}/configuraciones/actualizar?token=${token}&configuracion=dia_cobros`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return actconfiguracion;
  },
  async actualizarConfiguracionHora(token, hora) {
    let formData = new FormData();
    formData.append("nuevo_valor", hora);
    const actconfiguracion = await Axios.post(
      `${Configuration.api.base}/configuraciones/actualizar?token=${token}&configuracion=hora_stock_ftp`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return actconfiguracion;
  },
  async listarClientesAdminCobros(token) {
    const clientes = await Axios.get(
      `${Configuration.api.base}/clientes/list?token=${token}&id=&plan_id=&email=&rut=&nombre=&activo=1&limit=1000&order_by=id&order_direction=asc`
    );
    return clientes.data;
  },
  async listarProveedoresCliente(token) {
    const proveedores = await Axios.get(
      `${Configuration.api.base}/clientes/9/proveedores/list?token=${token}&id=&nombre=&disponibilidad=&activo=1&limit=1000&order_by=id&order_direction=asc`
    );
    return proveedores.data;
  },
  async listarProductosCliente(token) {
    const productos = await Axios.get(
      `${Configuration.api.base}/productos/list?token=${token}&id&nombre=&marca_id=&upc&ean&activo=1&order_direction=asc&limit=1000&order_by=id`
    );
    return productos.data;
  },
  async listarClientesProductos(token, id) {
    const listarClientes = await Axios.get(
      `${Configuration.api.base}/clientes/${id}/productos/list?token=${token}&id_interno=&nombre=&marca_id&upc&ean&activo=1&order_by=id&order_direction=asc&limit=1000`
    );
    return listarClientes.data;
  },
  async exportarProdProvee(id, token) {
    const exportar = await Axios.get(
      `${Configuration.api.base}/proveedores/${id}/productos/exportar?token=${token}`
    );
    return exportar.data;
  },
  async exportarProdClientes(id, token) {
    const exportarC = await Axios.get(
      `${Configuration.api.base}/proveedores/${id}/clientes/exportar?token=${token}`
    );
    return exportarC.data;
  },
  async buscarClientesProveedor(token, id, nombre, cod_interno, page) {
    const buscarC = await Axios.get(
      `${Configuration.api.base}/productos/list?token=${token}&id=${id}&nombre=${nombre}&codigo_interno=${cod_interno}&marca_id=&upc&ean&activo=1&order_direction=asc&limit=10&order_by=id&page=${page}`
    );
    return buscarC.data;
  },
  relacionarNuevoProveedorProveedor(token, id_proveedor, producto) {
    //duda con los datos
    let formData = new FormData();
    formData.append("sku", producto.sku);
    formData.append("stock", producto.stock);
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/proveedores/${id_proveedor}/productos/agregar/${producto.id}?token=${token}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  buscarcClientesProveedor(token, id, email, rut, nombre, page) {
    return new Promise((resolve, reject) => {
      Axios.get(
        `${Configuration.api.base}/clientes/list?token=${token}&id=${id}&plan_id=&email=${email}&rut=${rut}&nombre=${nombre}&activo=&limit=&order_by=id&order_direction=asc&limit=&page=${page}`
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  relacionarProveedorCliente(id_proveedor, token, id_cliente) {
    let formData = new FormData();
    formData.append("proveedores[0][id]", id_proveedor);
    return new Promise((resolve, reject) => {
      Axios.post(
        `${Configuration.api.base}/clientes/${id_cliente}/proveedores/agregar?token=${token}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async quitarRelacionClientes(token, id_cliente, id_proveedores) {
    let formData = new FormData();
    formData.append("proveedores[0][id]", id_proveedores);
    const actconfiguracion = await Axios.post(
      `${Configuration.api.base}/clientes/${id_cliente}/proveedores/quitar?token=${token}`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return actconfiguracion;
  },
  async exportarformatoCsv(token) {
    const exportarformatoCsv = await Axios.get(
      `${Configuration.api.base}/productos/exportar-formato-masivo-stock?token=${token}`
    );
    return exportarformatoCsv;
  },
  async quitarRelacionClientes(token, id_cliente, id_proveedores) {
    let formData = new FormData();
    formData.append("proveedores[0][id]", id_proveedores);
    const actconfiguracion = await Axios.post(
      `${Configuration.api.base}/clientes/${id_cliente}/proveedores/quitar?token=${token}`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return actconfiguracion;
  },
  // async actualizarformatoCsv(token,file){
  //     console.log('file:',file)
  //     let formData = new FormData();
  //     formData.append('archivo', file)
  //     console.log('formData', formData)
  //     const actualizarformatoCsv = await Axios.post(`${Configuration.api.base}/productos/actualizarStockCsv?=${token}`,
  //     formData,
  //     {
  //         headers: {'Content-Type': 'multipart/form-data' }
  //     })
  //     return actualizarformatoCsv
  // },
  async actualizarformatoCsv(token, formData) {
    const actualizarformatoCsv = await Axios.post(
      `${Configuration.api.base}/productos/actualizarStockCsv?token=${token}`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return actualizarformatoCsv.data;
  },
  editarStockProveedor(producto, token, id) {
    let formData = new FormData();
    formData.append("sku", producto.sku);
    formData.append("stock", producto.stock);
    return new Promise((resolve, reject) => {
      Axios({
        method: "post",
        url: `${Configuration.api.base}/productos/editar/${producto.id}/${id}?token=${token}`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async exportarProductosclientesstockv2(clienteid, token) {
    const exportarformatoProveedores = await Axios.get(
      `${Configuration.api.base}/clientes/${clienteid}/v2/productos/exportar?token=${token}`
    );
    return exportarformatoProveedores;
  },
};
