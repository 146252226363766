import store from './store'

export const authenticationRequired = (to, from, next) => {
  if (store.state.token != null) {
    next()
  } else {
   // store.commit("logout");
    next('/')
  }
}
