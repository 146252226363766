// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/vee-validate'

import vuetify from './plugins/vuetify'
import i18n from './i18n'
import VueCurrencyFilter from 'vue-currency-filter'

import { rutInputDirective } from 'vue-dni';
import { rutFilter } from 'vue-dni';
import titleMixin from './titleMixin';

Vue.mixin(titleMixin);

Vue.filter('rutFilter', rutFilter);

Vue.directive('rut', rutInputDirective);
export const EventBus = new Vue
Vue.config.productionTip = false
Vue.use(require('vue-moment'))
Vue.use(VueCurrencyFilter, {
  symbol: '$',
  thousandsSeparator: '.',
  fractionCount: 0,
  fractionSeparator: ',',
  symbolPosition: 'front',
  symbolSpacing: true,
  avoidEmptyDecimals: undefined,
})

Vue.filter("currency", function(value) {
  let strValue = value.toString();
  if (value.toString().length == 4) {
    return `$ ${
      strValue[0]
    }.${strValue
      .split("")
      .slice(1, 4)
      .join("")} `;
  } else {
    return `$ ${value.toLocaleString(
      'es-ES'
    )}`;
  }
});


new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
