<template>
  <v-app>
    <dashboard-core-app-bar v-if="$route.path != '/clientes' && $route.path != '/admin' && $route.path != '/proveedor' && $route.path != '/' " />
    <v-content>
      <dashboard-core-drawer v-if="$route.path != '/clientes'  && $route.path != '/admin' && $route.path != '/proveedor' && $route.path != '/' " />
      <router-view />
      <Succes />
    </v-content>
  <!--Float /-->
  </v-app>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Login from '@/views/dashboard/pages/Login'
  import { EventBus } from '@/main'
  import API from '@/api'
  import Succes from '@/views/dashboard/components/core/Succes'

  export default {
    name: 'App',
    components: {
      Login,
      DashboardCoreAppBar: () => import('@/views/dashboard/components/core/AppBar'),
      DashboardCoreDrawer: () => import('@/views/dashboard/components/core/Drawer'),
      Succes,
      //  Float
    },
    data () {
      return {
        fab: false,
        rutas: [],
      }
    },
    computed: {
      ...mapState(['user', 'token']),
    },
    watch: {
      $route () {
        this.getRoutes()
      },
    },
    created () {
      this.getRoutes()
      EventBus.$on('logout', () => {
        this.$store.commit('logout')
        this.$router.push({
          path: '/clientes',
        })
      })

      if (this.$store.state.token != null) {
        if (this.$store.state.tipoUser === 'cliente') {
          API.verCliente(this.$store.state.token, this.$store.state.user.id)
            .then((res) => {
              this.RegisterUser(res.data.respuesta)
            })
            .catch(() => {
            })
        }
      }

      if (this.$store.state.token != null) {
        let expireTime = new Date(this.$store.state.expireDate)

        let thirtyMinutes = new Date(this.$store.state.expireDate)
        thirtyMinutes.setMinutes(thirtyMinutes.getMinutes() - 30)

        if (Date.now() > expireTime.getTime() || Date.now() >= thirtyMinutes.getTime()) {
          this.$router.push({
            path: '/',
          })
        }
      }
    },
    methods: {
      ...mapActions(['RegisterToken', 'RegisterexpireDate', 'RegisterUser']),
      getRoutes () {
        this.$data.rutas = this.$route.matched
        if ((this.$data.rutas.length !== 0) && (this.$data.rutas.length > 1)) {
          this.$data.rutas.forEach((element) => {
            element.disabled = false
          })
          let count = this.$data.rutas.length
          this.$data.rutas[count - 1].disabled = true
        }
      },
    },
  }
</script>
<style lang="css">
* {
  word-break: break-word !important;
}
.v-data-table>.v-data-table__wrapper>table>tbody>tr>td, .v-data-table>.v-data-table__wrapper>table>tfoot>tr>td, .v-data-table>.v-data-table__wrapper>table>thead>tr>td {
    font-size: .85rem !important;
    height: 40px !important;
}

</style>
