import { authenticationRequired } from "@/auth";
import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/views/dashboard/layout/index.vue'
export default [{
    path: '/proveedor/editar-proveedor',
    name: 'proveedor-productos',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/proveedor/Editar-proveedor.vue'),
        beforeEnter: authenticationRequired,
        props: true,
      },
      {
        path: '/proveedor/editar-proveedor-perfil/:id',
        name: 'editar-proveedor-perfil',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/proveedor/Editar-proveedor-perfil'),
        beforeEnter: authenticationRequired,
        props: true,
      },
  {

        path: '/proveedor/ver-producto/:id_proveedor/:id',
        name: 'ver-productos-stock',
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/proveedor/Ver-productos.vue'),
        beforeEnter: authenticationRequired,
        props: true,
      },
      {
        path: '/proveedor/editar-producto/:id_proveedor/:id',
        name: 'editar-productos-stock',
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/proveedor/Editar-productos.vue'),
        beforeEnter: authenticationRequired,
        props: true,
      },
      {
        path: '/proveedor/edicion-masiva-producto-stock',
        name: 'edicion-masiva-productos-stock',
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/pages/proveedor/Edicion-masiva-productos.vue'),
        beforeEnter: authenticationRequired,
        props: true,
      },
]
