<template>
  <v-card v-bind="$attrs" :class="classes" class="v-card--material pa-3">
    <div class="d-flex justify-space-between">
      <v-avatar
        v-if="avatar"
        size="128"
        class="mx-auto v-card--material__avatar elevation-3"
        color="grey"
      >
        <v-img :src="avatar" />
      </v-avatar>
      <div class="d-flex" v-else>
        <v-sheet
          :class="{
            'pa-4': !$slots.image,
          }"
          :color="color"
          :max-height="icon ? 90 : undefined"
          :width="icon ? 'auto' : '100%'"
          elevation="3"
          class="text-start v-card--material__heading mb-n6 d-flex"
          dark
        >
          <slot v-if="$slots.heading" name="heading" />

          <slot v-else-if="$slots.image" name="image" />

          <div
            v-else-if="title && !icon"
            class="display-1 font-weight-light"
            v-text="title"
          />

          <v-icon v-else-if="icon" size="32" v-text="icon" />

          <div v-if="text" class="headline font-weight-thin" v-text="text" />
        </v-sheet>

        <div v-if="$slots['after-heading']" class="ml-6">
          <slot name="after-heading" />
        </div>

        <div v-else-if="icon && title" class="ml-4">
          <div class="card-title font-weight-light" v-text="title" />
        </div>
      </div>
      
        <div class="text-center flex-grow-1 py-0 ma-0" v-if="registros">
          Registros Encontrados : {{ registros }}
        </div>
      

      <!-- ICONOS ACCIONES ACCESO RAPIDO-->
      <v-row class="mb-16 flex-grow-0 flex-shrink-0">
        <!-- 1er ICON: AGREGAR PRODUCTOS -->
        <v-col v-if="showBtn.add">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-hover v-slot:default="{ hover }" open-delay="200">
                <v-btn
                  icon
                  dark
                  rounded
                  :elevation="hover ? 0 : 6"
                  :color="hover ? 'secondaryDark' : 'secondary'"
                  v-bind="attrs"
                  v-on="on"
                  @click="onAddBtn"
                >

                  <v-icon>mdi-cart-plus</v-icon>
                </v-btn>
              </v-hover>
            </template>
            <span v-text="`Agregar ${title}`"/>
          </v-tooltip>
        </v-col>

        <!-- 2do ICON: EXPORTAR PRODUCTOS -->
        <v-col v-if="showBtn.export">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-hover v-slot:default="{ hover }" open-delay="200">
                <v-btn
                  icon
                  dark
                  rounded
                  :elevation="hover ? 0 : 6"
                  :color="hover ? 'primaryDark' : 'primary'"
                  v-bind="attrs"
                  v-on="on"
                  @click="onExportBtn"
                >
                  <v-icon>mdi-cart-arrow-right</v-icon>
                </v-btn>
              </v-hover>
            </template>
            <span v-text="`Exportar ${title}`"/>
          </v-tooltip>
        </v-col>

       <!-- 3er ICON: CREACION MASIVA -->
        <v-col v-if="showBtn.addMasive">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-hover v-slot:default="{ hover }" open-delay="200">
                <v-btn
                  icon
                  dark
                  rounded
                  :elevation="hover ? 0 : 6"
                  :color="hover ? 'secondaryDark' : 'secondary'"
                  v-bind="attrs"
                  v-on="on"
                  :to="showBtn.addMasive.to"
                >
                  <v-icon>mdi-table-plus</v-icon>
                </v-btn>
              </v-hover>
            </template>
            <span v-text="`Creación masiva ${title}`"/>
          </v-tooltip>
        </v-col>


       <!-- 4to ICON: EDICION MASIVA -->
        <v-col v-if="showBtn.exportMasive">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-hover v-slot:default="{ hover }" open-delay="200">
                <v-btn
                  icon
                  dark
                  rounded
                  :elevation="hover ? 0 : 6"
                  :color="hover ? 'infoDark' : 'info'"
                  v-bind="attrs"
                  v-on="on"
                  :to="showBtn.exportMasive.to"
                >
                  <v-icon>mdi-table-edit</v-icon>
                </v-btn>
              </v-hover>
            </template>
            <span v-text="`Edición masiva ${title}`"/>
          </v-tooltip>
        </v-col>

        <!-- último ICON: MAS ACCIONES -->

        <v-col v-if="acciones">
          <v-menu offset-y>
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                  <v-hover v-slot="{ hover }" open-delay="200">
                    <v-btn
                      icon
                      :elevation="hover ? 0 : 6"
                      :color="hover ? 'greyDark' : 'grey'"
                      dark
                      v-bind="attrs"
                      v-on="{ ...tooltip, ...menu }"
                    >
                      <v-icon v-if="fab"> mdi-close </v-icon>
                      <v-icon v-else> mdi-dots-vertical-circle </v-icon>
                    </v-btn>
                  </v-hover>
                </template>
                <span>Más acciones</span>
              </v-tooltip>
            </template>

            <v-list width="250">
              <div
                class="pa-0 ma-0"
                v-for="(item, index) in enlaces"
                :key="index"
              >
                <v-list-item v-if="!item.to.export" :to="item.to">
                  <v-list-tile class="v-list-item">
                    <v-list-tile-title>{{ item.text }}</v-list-tile-title>
                  </v-list-tile>
                </v-list-item>
                <v-list-item
                  v-else-if="item.to.name == 'Exportar'"
                  @click="exportar"
                >
                  <v-list-tile class="v-list-item">
                    <v-list-tile-title>{{ item.text }}</v-list-tile-title>
                  </v-list-tile>
                </v-list-item>
                <v-list-item
                  v-else-if="item.to.name == 'ExportarCSV'"
                  @click="ExportarCSV"
                >
                  <v-list-tile class="v-list-item">
                    <v-list-tile-title>{{ item.text }}</v-list-tile-title>
                  </v-list-tile>
                </v-list-item>
                <v-list-item
                  v-else-if="item.to.name == 'ActualizarStock'"
                  @click="ActualizarStock"
                >
                  <v-list-tile class="v-list-item">
                    <v-list-tile-title>{{ item.text }}</v-list-tile-title>
                  </v-list-tile>
                </v-list-item>
                <v-list-item
                  v-else-if="item.to.name == 'ExportarProductoStock'"
                  @click="ExportarProductoStock"
                >
                  <v-list-tile class="v-list-item">
                    <v-list-tile-title>{{ item.text }}</v-list-tile-title>
                  </v-list-tile>
                </v-list-item>
              </div>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </div>

    <slot />

    <template v-if="$slots.actions">
      <v-divider class="mt-2" />

      <v-card-actions class="pb-0">
        <slot name="actions" />
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>

export default {
  name: "MaterialCard",

  props: {
    showBtn:{
      type:Object,
      default:()=>({})
    },
    avatar: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "success",
    },
    icon: {
      type: String,
      default: undefined,
    },
    image: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    registros: {
      type: Number,
      default: "",
    },
    acciones: {
      type: String,
      default: "",
    },
    enlaces: {
      type: Array,
      default: "",
    },
  },
  data: () => ({
    fab: false,
  }),

  computed: {
    classes() {
      return {
        "v-card--material--has-heading": this.hasHeading,
      };
    },
    hasHeading() {
      return Boolean(this.$slots.heading || this.title || this.icon);
    },
    hasAltHeading() {
      return Boolean(this.$slots.heading || (this.title && this.icon));
    },
  },
  methods: {
    exportar() {
      this.$emit("exportar", true);
    },
    ExportarCSV() {
      this.$emit("ExportarCSV", true);
    },
    ActualizarStock() {
      this.$emit("ActualizarStock", true);
    },
    ExportarProductoStock() {
      this.$emit("ExportarProductoStock", true);
    },
    onAddBtn(){
      this.$emit("onAddBtn")
    },
    onExportBtn(){
      this.$emit("onExportBtn")
    },
  },
};
</script>

<style lang="sass">
.v-speed-dial--bottom
  bottom: 0px !important
.v-card--material
  &__avatar
    position: relative
    top: -64px
    margin-bottom: -32px

  &__heading
    position: relative
    top: -40px
    transition: .3s ease
    z-index: 1
</style>
